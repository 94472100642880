import React, { useState, useEffect, useRef } from "react";
import characters from "../../data/characters";
import CharacterCard from "./CharacterCard";

const CharacterSlider: React.FC = () => {
	const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(0);
	const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
	const prevIndexRef = useRef<number | null>(null);
	const anglesRef = useRef<number[]>([]);

	// 初期角度を設定
	useEffect(() => {
		anglesRef.current = characters.map(() => Math.random() * 5 - 5);
	}, []);

	useEffect(() => {
		cardRefs.current.forEach((card, index) => {
			if (card) {
				card.style.transition = "none";
				if (index === selectedCharacterIndex) {
					card.style.transition = "transform 2s, z-index 2s, filter 2s";
					card.style.zIndex = "10";
					card.style.transform = "rotate(0deg) translateY(0)";
					card.style.filter = "grayscale(0%)";
				} else if (index === prevIndexRef.current) {
					card.style.transition = "transform 2s, filter 2s";
					card.style.zIndex = "5";
					card.style.transform = "rotate(0deg) translateY(-100vh)";
					card.style.filter = "grayscale(100%)";
					setTimeout(() => {
						card.style.transition = "none";
						const newAngle = Math.random() * 5 - 5;
						anglesRef.current[index] = newAngle;
						card.style.transform = `rotate(${newAngle}deg) translateY(100vh)`;
						setTimeout(() => {
							card.style.transition = "transform 2s, filter 2s";
							card.style.transform = `rotate(${newAngle}deg) translateY(0)`;
							card.style.zIndex = "1";
							card.style.filter = "grayscale(100%)";
						}, 0);
					}, 800); // 0.8秒後に背後に戻す
				} else {
					card.style.zIndex = "1";
					card.style.transform = `rotate(${anglesRef.current[index]}deg)`;
					card.style.filter = "grayscale(100%)";
				}
			}
		});
		prevIndexRef.current = selectedCharacterIndex;
	}, [selectedCharacterIndex]);

	const handleCharacterSelect = (index: number) => {
		setSelectedCharacterIndex(index);
	};

	return (
		<div>
			<div>
				<div className="card-container">
					{characters.map((character, index) => (
						<div
							key={index}
							ref={(el) => (cardRefs.current[index] = el)}
							className="chara-card"
						>
							<CharacterCard character={character} />
						</div>
					))}
				</div>
			</div>
			<div className="chara-list">
				{characters.map((character, index) => (
					<div
						key={index}
						className={`item ${
							selectedCharacterIndex === index ? "selected" : ""
						}`}
						style={{
							background: `${character.themeColor}`,
						}}
						onClick={() => handleCharacterSelect(index)}
					>
						{selectedCharacterIndex === index && (
							<span className="hyouji">表示中</span>
						)}
						<img
							src={`img/characters/${character.smallImage}`}
							alt={character.name}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default CharacterSlider;
