import React, {
	createContext,
	useContext,
	useState,
	useRef,
	useEffect,
	useCallback,
} from "react";

interface BGMContextType {
	bgmEnabled: boolean;
	toggleBgm: () => void;
	audioRef: React.RefObject<HTMLAudioElement>;
	preloadAudio: () => void;
	isAudioLoaded: boolean;
	playBGM: () => void;
	pauseBGM: () => void;
}

const BGMContext = createContext<BGMContextType>({
	bgmEnabled: false,
	toggleBgm: () => {},
	audioRef: { current: null },
	preloadAudio: () => {},
	isAudioLoaded: false,
	playBGM: () => {},
	pauseBGM: () => {},
});

export const BGMProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [bgmEnabled, setBgmEnabled] = useState(false);
	const [isAudioLoaded, setIsAudioLoaded] = useState(false);
	const audioRef = useRef<HTMLAudioElement | null>(null);

	const toggleBgm = () => {
		setBgmEnabled((prev) => !prev);
	};

	const preloadAudio = useCallback(() => {
		if (audioRef.current && !isAudioLoaded) {
			audioRef.current.preload = "auto";
			audioRef.current.load();
			audioRef.current.oncanplaythrough = () => {
				setIsAudioLoaded(true);
			};
		}
	}, [isAudioLoaded]);

	const playBGM = useCallback(() => {
		if (audioRef.current && isAudioLoaded && bgmEnabled) {
			audioRef.current
				.play()
				.catch((error) => console.error("Error playing audio:", error));
		}
	}, [isAudioLoaded, bgmEnabled]);

	const pauseBGM = useCallback(() => {
		if (audioRef.current) {
			audioRef.current.pause();
		}
	}, []);

	return (
		<BGMContext.Provider
			value={{
				bgmEnabled,
				toggleBgm,
				audioRef,
				preloadAudio,
				isAudioLoaded,
				playBGM,
				pauseBGM,
			}}
		>
			<audio ref={audioRef} src="sound/bg-sound.mp3" loop></audio>
			{children}
		</BGMContext.Provider>
	);
};

export const useBGM = () => useContext(BGMContext);
