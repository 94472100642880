import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet-async";
import Cookies from "js-cookie";
import { SITE_NAME } from "../constants/siteConfig";

const FcOnly: React.FC = () => {
	const [aikotoba, setAikotoba] = useState("");
	const navigate = useNavigate();

	// 合言葉の正解
	const correctAikotoba = "future"; // ここに正解の合言葉を設定

	// ページ初期表示時にCookieを確認
	useEffect(() => {
		const cookieAikotoba = Cookies.get("aikotoba_correct");
		if (cookieAikotoba === "true") {
			// Cookieに正解フラグがあれば直接FCNewsページに遷移
			navigate("/fc-news");
		}
	}, [navigate]);

	// 合言葉の入力を監視
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const input = event.target.value;
		setAikotoba(input);

		// 合言葉が正解と一致したらCookieを設定し、指定ページへ遷移
		if (input === correctAikotoba) {
			Cookies.set("aikotoba_correct", "true", { expires: 7 }); // Cookieに7日間保存
			navigate("/fc-news");
		}
	};

	return (
		<Layout>
			<div>
				<Helmet>
					<title>Tier 4 指定機外秘情報｜{SITE_NAME}</title>
				</Helmet>
				<div className="single-page inner">
					<header className="single-page-header">
						<h1 className="page-title font-accent">FC ONLY</h1>
						<p className="fc-only-subtitle">Tier 4 指定機外秘情報</p>
					</header>
					<div className="fc-content">
						<p className="overview">
							本ページ閲覧におけるセキュリティクリアランスはFC月額支援者のみに与えられる
						</p>
						<a
							className="goto-membership-button"
							href="https://note.com/studiogoshu/membership/join"
							target="_blank"
						>
							<span>Studio Goshu 超プロデューサーの開発室</span>
							<span>月額支援はこちら</span>
						</a>
						<p className="aikotoba-desc">合言葉を入力してください</p>
						<input
							type="text"
							value={aikotoba}
							onChange={handleChange}
							placeholder="合言葉を入力"
							className="aikotoba-input"
						/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default FcOnly;
