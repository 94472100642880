import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useLayout } from "../../contexts/LayoutContext";

type LayoutProps = {
	children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
	const { showHeader, showFooter } = useLayout();

	return (
		<div>
			{showHeader && <Header />}
			<main>{children}</main>
			{showFooter && <Footer />}
		</div>
	);
};

export default Layout;
