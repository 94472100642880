import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../components/layout/Layout";
import { SITE_NAME } from "../constants/siteConfig";

const NotFound: React.FC = () => {
	return (
		<Layout>
			<div>
				<Helmet>
					<title>404 Not Found｜{SITE_NAME}</title>
				</Helmet>
				<div className="single-page inner">
					<header className="single-page-header">
						<h1 className="page-title">Not Found</h1>
						<div className="not-found">
							<span className="glitch">404</span>
							<span className="offset">404</span>
							<span>404</span>
						</div>
					</header>
				</div>
			</div>
		</Layout>
	);
};

export default NotFound;
