import React, { useState, useEffect, useCallback, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { SITE_NAME } from "../constants/siteConfig";
import { useBGM } from "../contexts/BGMContext";
import { useLayout } from "../contexts/LayoutContext";
import Layout from "../components/layout/Layout";
import Hero from "../features/home/Hero";
import Story from "../features/home/Story";
import Characters from "../features/home/Characters";
import Movie from "../features/home/Movie";
import Credit from "../features/home/Credit";
import LoadingScreen from "../features/home/LoadingScreen";
import Ending from "../features/home/Ending";

const IDLE_TIMEOUT = 120000;
const TRANSITION_DELAY = 5000;
const ENDING_DELAY = 3000;
const FADE_DURATION = 1000;
const BGM_DELAY = 300;

const Home: React.FC = () => {
	const [loadingComplete, setLoadingComplete] = useState(false);
	const [idle, setIdle] = useState(false);
	const [showEnding, setShowEnding] = useState(false);
	const [fadeToBlack, setFadeToBlack] = useState(false);
	const [blackScreenOpacity, setBlackScreenOpacity] = useState(0);
	const [isTransitioning, setIsTransitioning] = useState(false);

	const { hash } = useLocation();
	const { bgmEnabled, audioRef, preloadAudio, playBGM, pauseBGM } = useBGM();
	const { setShowHeader, setShowFooter } = useLayout();

	const lastActivityTimeRef = useRef(Date.now());
	const idleTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const audioContextRef = useRef<AudioContext | null>(null);
	const gainNodeRef = useRef<GainNode | null>(null);
	const sourceNodeRef = useRef<MediaElementAudioSourceNode | null>(null);
	const audioSetupCompleteRef = useRef(false);

	useEffect(() => {
		if (hash) setLoadingComplete(true);
		preloadAudio();
	}, [hash, preloadAudio]);

	useEffect(() => {
		setShowHeader(loadingComplete && !showEnding);
		setShowFooter(loadingComplete && !showEnding);
	}, [loadingComplete, showEnding, setShowHeader, setShowFooter]);

	const setupAudioContext = useCallback(() => {
		if (!audioRef.current || audioSetupCompleteRef.current) return;

		audioContextRef.current = new (window.AudioContext ||
			(window as any).webkitAudioContext)();
		sourceNodeRef.current = audioContextRef.current.createMediaElementSource(
			audioRef.current
		);
		gainNodeRef.current = audioContextRef.current.createGain();
		sourceNodeRef.current.connect(gainNodeRef.current);
		gainNodeRef.current.connect(audioContextRef.current.destination);

		audioSetupCompleteRef.current = true;
	}, [audioRef]);

	const handleLoadingComplete = useCallback(() => {
		setLoadingComplete(true);
		if (bgmEnabled && audioRef.current) {
			setupAudioContext();
			setTimeout(playBGM, BGM_DELAY);
		}
	}, [bgmEnabled, audioRef, setupAudioContext, playBGM]);

	const fadeOutAudio = useCallback(() => {
		if (!audioContextRef.current || !gainNodeRef.current || !audioRef.current)
			return;

		const startTime = audioContextRef.current.currentTime;
		const endTime = startTime + FADE_DURATION / 1000;

		gainNodeRef.current.gain.setValueAtTime(
			gainNodeRef.current.gain.value,
			startTime
		);
		gainNodeRef.current.gain.linearRampToValueAtTime(0, endTime);

		setTimeout(pauseBGM, FADE_DURATION);
	}, [pauseBGM]);

	const fadeToBlackScreen = useCallback(() => {
		setFadeToBlack(true);
		const fadeInterval = setInterval(() => {
			setBlackScreenOpacity((prev) => {
				if (prev >= 1) {
					clearInterval(fadeInterval);
					return 1;
				}
				return prev + 0.1;
			});
		}, 100);
	}, []);

	const startTransition = useCallback(() => {
		if (!loadingComplete) return;
		setIsTransitioning(true);
		setIdle(true);
		setTimeout(() => {
			fadeOutAudio();
			fadeToBlackScreen();
			setTimeout(() => setShowEnding(true), ENDING_DELAY);
		}, TRANSITION_DELAY);
	}, [loadingComplete, fadeOutAudio, fadeToBlackScreen]);

	const handleActivity = useCallback(() => {
		if (isTransitioning) return;
		lastActivityTimeRef.current = Date.now();
		setIdle(false);
		setFadeToBlack(false);
		setBlackScreenOpacity(0);
		setShowEnding(false);
	}, [isTransitioning]);

	useEffect(() => {
		const checkIdleState = () => {
			if (!loadingComplete) return;
			const currentTime = Date.now();
			const idleTime = currentTime - lastActivityTimeRef.current;

			if (idleTime >= IDLE_TIMEOUT && !isTransitioning) {
				startTransition();
			} else if (!isTransitioning) {
				idleTimeoutRef.current = setTimeout(checkIdleState, 1000);
			}
		};

		const events = [
			"mousemove",
			"keydown",
			"scroll",
			"touchstart",
			"touchmove",
		];
		events.forEach((event) => window.addEventListener(event, handleActivity));

		if (loadingComplete) {
			idleTimeoutRef.current = setTimeout(checkIdleState, 1000);
		}

		return () => {
			events.forEach((event) =>
				window.removeEventListener(event, handleActivity)
			);
			if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
		};
	}, [handleActivity, isTransitioning, startTransition, loadingComplete]);

	const renderContent = () => {
		if (!loadingComplete && !hash) {
			return <LoadingScreen onLoadingComplete={handleLoadingComplete} />;
		}
		if (showEnding) {
			return <Ending />;
		}
		return (
			<>
				{idle && (
					<div
						className="noise-overlay"
						style={{
							backgroundImage: 'url("/video/noise.webp")',
							mixBlendMode: "difference",
							position: "fixed",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							zIndex: 10000,
							opacity: 0.5,
						}}
					/>
				)}
				{fadeToBlack && (
					<div
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							backgroundColor: "black",
							opacity: blackScreenOpacity,
							transition: "opacity 3s",
							zIndex: 10001,
						}}
					/>
				)}
				<Hero />
				<Story />
				<Characters />
				<Movie />
				<Credit />
			</>
		);
	};

	return (
		<Layout>
			<Helmet>
				<title>{SITE_NAME}</title>
			</Helmet>
			{renderContent()}
		</Layout>
	);
};

export default Home;
