import React, { useState, useEffect } from "react";
import { Character } from "../../data/characters";
import { Parser } from "../../utils/parser";

interface CharacterCardProps {
	character: Character;
}

const CharacterCard: React.FC<CharacterCardProps> = ({ character }) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [fade, setFade] = useState(false);

	useEffect(() => {
		if (character.images.length > 1) {
			const intervalId = setInterval(() => {
				setFade(true);
				setTimeout(() => {
					setCurrentImageIndex(
						(prevIndex) => (prevIndex + 1) % character.images.length
					);
					setFade(false);
				}, 500); // 0.5 seconds for the fade effect
			}, 2000); // Switch images every 2 seconds

			return () => clearInterval(intervalId); // Clear interval on component unmount
		}
	}, [character.images.length]);

	return (
		<>
			<div className="left">
				<div
					className="color-label"
					style={{
						background: `${character.themeColor}`,
					}}
				>
					<span className="sep"></span>
					<span className="sep"></span>
				</div>
				<div
					className="image"
					style={{
						background: `${character.themeColor}`,
					}}
				>
					{character.images.map((image, index) => (
						<img
							key={index}
							src={`img/characters/${image}`}
							alt={`${character.name} ${index + 1}`}
							style={{
								transition: "opacity 0.5s ease-in-out",
								opacity:
									index === currentImageIndex
										? fade
											? 0
											: 1
										: index ===
										  (currentImageIndex + 1) % character.images.length
										? fade
											? 1
											: 0
										: 0,
							}}
						/>
					))}
				</div>
			</div>
			<div className="right">
				<p className="card-title">保菌者・非保菌者調査報告書</p>
				<div className="overview">
					<div className="name-container overview-item">
						<span className="label">氏名</span>
						<span className="field">
							<span className="furigana">{character.furigana}</span>
							<h3 className="name value">{character.name} </h3>
						</span>
					</div>
					<div className="age-container overview-item">
						<span className="field">
							<span className="label">年齢</span>
							<span className="value">{character.age} </span>
						</span>
					</div>
					<div className="pathogen-container overview-item">
						<span className="label">保菌種別</span>
						<span className="field">
							<h3 className="value">{Parser(character.pathogenType)}</h3>
						</span>
					</div>
					<div className="synesthesia-container overview-item">
						<span className="label">
							{Parser(
								'[GlossaryTerm title="保菌者"]シネスティマ[/GlossaryTerm]'
							)}
						</span>
						<span className="field">
							<h3 className="value">{Parser(character.synesthesia)}</h3>
						</span>
					</div>
				</div>
				<div className="longtext-container organization">
					<h3 className="title">所属機関名</h3>
					<p>{Parser(character.organization)}</p>
					<img
						className="organization-logo"
						src={`img/characters/${character.logoImage}`}
					/>
				</div>
				<div className="longtext-container">
					<h3 className="title">調査概要</h3>
					<p>{Parser(character.surveySummary)}</p>
				</div>
			</div>
		</>
	);
};

export default CharacterCard;
