import React, { useRef, useEffect, useState } from "react";
import GlossaryTerm from "../../components/glossary/GlossaryTerm";
import { Parser } from "../../utils/parser";

const Story: React.FC = () => {
	return (
		<section className="story">
			<h2 className="heading left" id="story">
				<span className="title">
					ST<span className="accent-color">O</span>RY
				</span>
				<span className="subtitle">物語解説</span>
			</h2>
			<div className="story-desc">
				<p>
					時は今より限りなく近い未来。
					<br />『
					{Parser(
						'[GlossaryTerm title="星屑の落涙"][Ruby rt="スターダスト・ティアドロップ"]星屑の落涙[/Ruby][/GlossaryTerm]'
					)}
					』という隕石衝突の大事変と、
					<br />
					その隕石に宿り太陽系外から飛来した「
					{Parser(
						'[GlossaryTerm title="扁桃核内細胞寄生因子"][Ruby rt="パラシトス"]扁桃核内細胞寄生因子[/Ruby][/GlossaryTerm]'
					)}
					」によって、人類世界は崩壊を迎えた。
				</p>
				<p>
					パラシトスは扁桃体、言い換えては人間の「感情」に寄生するとされ、
					<br />
					宿主の強い情動・感情と感応し突然変異にも似たあらゆる変化を宿主に与える。
					<br />
					寄生され異形化した多くの人類と隕石被害により、地上は荒廃と化した。
				</p>
				<p>
					その後かつて東京と呼ばれていた場所、「
					{Parser(
						'[GlossaryTerm title="第一中央特区 武蔵"]第一中央特区 武蔵[/GlossaryTerm]'
					)}
					」は、
					<br />
					衛生管理保全機構主導の元で寄生された人類とそうでない人類を区別し、
					<br />
					前者を都市外へ排斥するような政策を執り行っている。
				</p>
				<p>
					かたや首都転覆を狙うテロ組織『
					{Parser(
						'[GlossaryTerm title="過たず招かれた災筐"][Ruby rt="パンドーラ"]過たず招かれた災筐[/Ruby][/GlossaryTerm]'
					)}
					』の首魁・空臥 明止。
				</p>
				<p>
					かたや保全機構第六課『アスクレピオスの杖』に所属するかつての朋友、執行士・十時
					玲止。
				</p>
				<p>
					そして『星屑の落涙』によって死亡した2人の友人・沓空 聖佳。
					<br />
					彼らの
					<ruby>
						憎悪<rt>アイ</rt>
					</ruby>
					が交錯する時、この世界は何を見るのか―――。
				</p>
			</div>
			<span className="outline-typo left">物語解説</span>
			<span className="outline-typo right">物語解説</span>
		</section>
	);
};

export default Story;
