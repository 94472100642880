import React, { useEffect, useState, useRef } from "react";

const Ending: React.FC = () => {
	const [opacity, setOpacity] = useState(0);
	const [showContent, setShowContent] = useState(false);
	const [bgOpacity, setBgOpacity] = useState(0);
	const endingRef = useRef<HTMLDivElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const typoRefs = useRef<(HTMLDivElement | null)[]>([]);

	useEffect(() => {
		document.body.style.backgroundColor = "black";
		const showContentTimeout = setTimeout(() => {
			setShowContent(true);
			setOpacity(1);
		}, 100);

		// Fade in the background
		const fadeBgInterval = setInterval(() => {
			setBgOpacity((prev) => {
				if (prev >= 0.8) {
					clearInterval(fadeBgInterval);
					return 0.8;
				}
				return prev + 0.1;
			});
		}, 100);

		const handleScroll = () => {
			if (endingRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = endingRef.current;
				if (scrollTop + clientHeight >= scrollHeight * 0.99) {
					console.log("Reached the bottom, reloading page...");
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			}
		};

		const endingElement = endingRef.current;
		if (endingElement) {
			endingElement.addEventListener("scroll", handleScroll);
		}

		// Start playing the video
		if (videoRef.current) {
			videoRef.current.play().catch((error) => {
				console.error("Error playing the video:", error);
			});
		}

		// Set up Intersection Observer for typo elements
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (
						entry.isIntersecting &&
						entry.boundingClientRect.top <= (window.innerHeight * 2) / 3
					) {
						entry.target.classList.add("fade-in-bottom");
						observer.unobserve(entry.target);
					}
				});
			},
			{ threshold: 0.1, rootMargin: "0px 0px -33% 0px" }
		);

		typoRefs.current.forEach((ref) => {
			if (ref) observer.observe(ref);
		});

		return () => {
			clearTimeout(showContentTimeout);
			clearInterval(fadeBgInterval);
			if (endingElement) {
				endingElement.removeEventListener("scroll", handleScroll);
			}
			observer.disconnect();
		};
	}, []);

	const images = Array.from(
		{ length: 7 },
		(_, i) => `img/ending/ending-typo-0${i + 1}.webp`
	);

	return (
		<>
			<video
				ref={videoRef}
				loop
				muted
				playsInline
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					objectFit: "cover",
					opacity: bgOpacity,
					transition: "opacity 1s",
					zIndex: -1,
				}}
			>
				<source src="video/welcome-bg.mp4" type="video/mp4" />
				Your browser does not support the video tag.
			</video>
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: 'url("img/ending/ending-bg.jpg")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					zIndex: 0,
					opacity: bgOpacity,
					transition: "opacity 1s",
				}}
			/>
			<div
				className="ending-message"
				ref={endingRef}
				style={{
					opacity: showContent ? opacity : 0,
					transition: "opacity 2s ease-in",
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					overflowY: "auto",
					padding: "120px 20px",
					color: "white",
					textAlign: "center",
					visibility: showContent ? "visible" : "hidden",
					zIndex: 1,
				}}
			>
				<div className="typo">
					{images.map((image, index) => (
						<div
							key={index}
							ref={(el) => (typoRefs.current[index] = el)}
							style={{
								opacity: 0,
								transform: "translateY(50px)",
								marginBottom: "20px",
							}}
						>
							<img
								src={image}
								alt={`Ending typography ${index + 1}`}
								style={{ maxWidth: "100%", height: "auto" }}
							/>
						</div>
					))}
					<div style={{ height: "100vh" }}></div>
				</div>
			</div>
			<div
				className="noise-overlay"
				style={{
					backgroundImage: 'url("video/noise.webp")',
					mixBlendMode: "difference",
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					opacity: 0.1,
					pointerEvents: "none",
					zIndex: 2,
				}}
			/>
		</>
	);
};

export default Ending;
