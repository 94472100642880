import React, { useState, useEffect, useRef } from "react";
import { useBGM } from "../../contexts/BGMContext";

interface LoadingScreenProps {
	onLoadingComplete: () => void;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ onLoadingComplete }) => {
	const [loading, setLoading] = useState(true);
	const [secondVideoVisible, setSecondVideoVisible] = useState(false);
	const [countdown, setCountdown] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});
	const [showContent, setShowContent] = useState(false);
	const [glitchEffect, setGlitchEffect] = useState(false);
	const [parasiticMessage, setParasiticMessage] = useState("");
	const firstVideoRef = useRef<HTMLVideoElement>(null);
	const secondVideoRef = useRef<HTMLVideoElement>(null);
	const { bgmEnabled, toggleBgm, preloadAudio } = useBGM();

	const targetDate = new Date("2024-09-29T09:00:00Z"); // 日本時間 2024年9月29日18時
	const isTestEnv =
		new URLSearchParams(window.location.search).get("test") === "true";

	useEffect(() => {
		const timer = setTimeout(() => setLoading(false), 500);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		const playVideo = (videoRef: React.RefObject<HTMLVideoElement>) => {
			if (videoRef.current) {
				videoRef.current
					.play()
					.catch((error) => console.error("Error playing video:", error));
			}
		};
		const playInterval = setInterval(() => playVideo(firstVideoRef), 1000);
		return () => clearInterval(playInterval);
	}, []);

	useEffect(() => {
		if (secondVideoRef.current) {
			secondVideoRef.current.onended = onLoadingComplete;
			secondVideoRef.current.muted = !bgmEnabled;
		}
	}, [secondVideoVisible, onLoadingComplete, bgmEnabled]);

	useEffect(() => {
		if (isTestEnv) {
			setShowContent(true);
			return;
		}

		const updateCountdown = () => {
			const now = new Date();
			const difference = targetDate.getTime() - now.getTime();

			if (difference <= 0) {
				if (difference > -1000) {
					// 規定時間から1秒以内
					typeParasiticMessage();
				} else {
					setShowContent(true);
				}
				return;
			}

			const days = Math.floor(difference / (1000 * 60 * 60 * 24));
			const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
			const minutes = Math.floor((difference / 1000 / 60) % 60);
			const seconds = Math.floor((difference / 1000) % 60);

			setCountdown({ days, hours, minutes, seconds });
		};

		updateCountdown();
		const countdownInterval = setInterval(updateCountdown, 1000);

		const glitchInterval = setInterval(() => {
			if (Math.random() < 0.1) {
				setGlitchEffect(true);
				setTimeout(() => setGlitchEffect(false), 100);
			}
		}, 1000);

		return () => {
			clearInterval(countdownInterval);
			clearInterval(glitchInterval);
		};
	}, [isTestEnv]);

	const typeParasiticMessage = () => {
		const fullMessage = "Parasitic spreading...";
		let index = 0;
		const interval = setInterval(() => {
			if (index < fullMessage.length) {
				setParasiticMessage(fullMessage.substring(0, index + 1));
				index++;
			} else {
				clearInterval(interval);
				setTimeout(() => {
					setParasiticMessage("");
					setShowContent(true);
				}, 1000); // 最後の文字が表示されてから1秒後に通常画面に遷移
			}
		}, 50);
	};

	const handleContinue = () => {
		setSecondVideoVisible(true);
		preloadAudio();
		if (secondVideoRef.current) {
			secondVideoRef.current.muted = !bgmEnabled;
			secondVideoRef.current
				.play()
				.catch((error) => console.error("Error playing second video:", error));
		}
	};

	if (loading) {
		return (
			<div className="loading">
				<div className="loading-effect"></div>
			</div>
		);
	}

	const videoStyle = {
		objectFit: "cover" as const,
		position: "absolute" as const,
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	};

	const countdownStyle: React.CSSProperties = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		color: "white",
		fontSize: "24px",
		fontWeight: "bold",
		width: "300px",
		textAlign: "center",
		letterSpacing: "2px",
	};

	const glitchStyle: React.CSSProperties = {
		...countdownStyle,
		textShadow: "2px 2px #ff00de, -2px -2px #00ff9d",
		letterSpacing: "-2px",
		fontStyle: "italic",
	};

	const parasiticStyle: React.CSSProperties = {
		...countdownStyle,
		fontSize: "20px",
		letterSpacing: "0.1em",
	};

	return (
		<div className="entrance">
			<div
				className="video-box"
				style={{
					position: "relative",
					width: "100%",
					height: "100vh",
					overflow: "hidden",
				}}
			>
				<video
					ref={firstVideoRef}
					muted
					loop
					playsInline
					autoPlay
					preload="auto"
					style={{
						...videoStyle,
						display: secondVideoVisible ? "none" : "block",
					}}
				>
					<source src="video/welcome-bg.mp4" type="video/mp4" />
				</video>
				<video
					ref={secondVideoRef}
					playsInline
					preload="auto"
					style={{
						...videoStyle,
						display: secondVideoVisible ? "block" : "none",
					}}
				>
					<source src="video/welcome-bg-start.mp4" type="video/mp4" />
				</video>
			</div>
			{!showContent && parasiticMessage === "" ? (
				<div style={glitchEffect ? glitchStyle : countdownStyle}>
					{`${countdown.days}d ${countdown.hours
						.toString()
						.padStart(2, "0")}h ${countdown.minutes
						.toString()
						.padStart(2, "0")}m ${countdown.seconds
						.toString()
						.padStart(2, "0")}s`}
				</div>
			) : parasiticMessage !== "" ? (
				<div style={parasiticStyle}>{parasiticMessage}</div>
			) : (
				!secondVideoVisible && (
					<div
						className="inner bgm-select"
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						<div className="row">
							<span>BGM : </span>
							<button
								className={`bgm-button ${bgmEnabled ? "on" : "off"}`}
								onClick={toggleBgm}
							>
								{bgmEnabled ? "ON" : "OFF"}
							</button>
						</div>
						<div className="row">
							<button className="go-to-home" onClick={handleContinue}>
								Go to Home
							</button>
						</div>
					</div>
				)
			)}
		</div>
	);
};

export default LoadingScreen;
