import React, { createContext, useContext, useState, ReactNode } from "react";

type LayoutContextType = {
	showHeader: boolean;
	showFooter: boolean;
	setShowHeader: (show: boolean) => void;
	setShowFooter: (show: boolean) => void;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const LayoutProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [showHeader, setShowHeader] = useState(true);
	const [showFooter, setShowFooter] = useState(true);

	return (
		<LayoutContext.Provider
			value={{ showHeader, showFooter, setShowHeader, setShowFooter }}
		>
			{children}
		</LayoutContext.Provider>
	);
};

export const useLayout = () => {
	const context = useContext(LayoutContext);
	if (context === undefined) {
		throw new Error("useLayout must be used within a LayoutProvider");
	}
	return context;
};
