import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/layout/Layout";

interface FCNewsArticle {
	id: string;
	title: string;
	content: string;
	createdAt: string;
	thumbnail?: {
		url: string;
	};
	category?: string;
}

const FCNewsDetail: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [article, setArticle] = useState<FCNewsArticle | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const fetchArticle = async () => {
			try {
				const response = await fetch(
					`https://parasitos-syndrome.microcms.io/api/v1/fc-only/${id}`,
					{
						headers: {
							"X-MICROCMS-API-KEY": "Xc8dv3BFTUG5obqxXnBA7wgiYWzH7VcoiANg",
						},
					}
				);
				const data = await response.json();
				setArticle(data);
				setLoading(false);
			} catch (error) {
				setError("Failed to load article");
				setLoading(false);
			}
		};

		fetchArticle();
	}, [id]);

	if (loading) {
		return <p>Loading...</p>;
	}

	if (error) {
		return <p>{error}</p>;
	}

	if (!article) {
		return <p>Article not found</p>;
	}

	return (
		<Layout>
			<div className="news-detail inner">
				<header className="news-header">
					<h1>{article.title}</h1>
					<p>{new Date(article.createdAt).toLocaleDateString()}</p>
					{article.thumbnail && (
						<img
							className="news-thumbnail"
							src={article.thumbnail.url}
							alt={article.title}
							style={{ width: "100%", height: "auto" }}
						/>
					)}
				</header>
				<div
					className="news-content"
					dangerouslySetInnerHTML={{ __html: article.content }}
				/>
				{article.category && <p>Category: {article.category}</p>}
			</div>
		</Layout>
	);
};

export default FCNewsDetail;
